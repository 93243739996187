import { Component, Inject, OnInit } from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from "@angular/material/dialog";

@Component({
	selector: "app-alert-message",
	templateUrl: "./alert-message.component.html",
	styleUrl: "./alert-message.component.css",
})
export class AlertMessageComponent implements OnInit {
	content = "";

	constructor(
		private dialogRef: MatDialogRef<AlertMessageComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.content = this.data.content;
	}

	close(value: boolean) {
		this.dialogRef.close(value);
	}
}
